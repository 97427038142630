import { ParsedUrlQuery } from 'querystring';
import { AvailabilityFilterKey, DefaultBaseFilterSetup, PaymentTypeFilterKey } from '../constants/site-consts';
import { getPageByDataTypeAlias, getPageById } from './api';
import { getAllBundles } from './api/hire/hessel-hire-bundles-api';
import { umbraco } from './api/models';
import { DealershipInformationPage, FilterConfiguration } from './api/models/umbraco';
import { CarExplorationModule, ProductListWithFilter, ShopProductList, SixBySixBundleSpot } from './api/models/umbraco/content-spot';
import { getShopProductsByCategory } from './api/shop/shop-product-api';
import { getCarExplorationCardData } from './api/vehicle/hessel-vehicle-module-api';
import { getCarByIds } from './api/vehicle/hessel-vehicle-pdp-api';
import { getProductList } from './api/vehicle/hessel-vehicle-plp-api';
import { createShopProductListData } from './mappers/shop/product-list.mapper';
import { mapCarDetailsToProductCard } from './mappers/vehicle/product-card.mapper';
import { createFacetArray } from './mappers/vehicle/product-list.mapper';
import { getSalesVideoDetails, submitActivity } from './api/sales-video/hessel-sales-video-api';
import { SalesVideoData } from './api/models/hessel-api/sales-video';

export const ssrForSalesVideo = async (query: ParsedUrlQuery): Promise<SalesVideoData | undefined> => {
    const videoId = query.guid as string | undefined;
    if (!videoId) return;

    const [salesVideoResponse] = await getSalesVideoDetails(videoId);
    if (!salesVideoResponse) return;

    const [departmentsResponse] = await getPageByDataTypeAlias('dealershipInformation');
    const department = (departmentsResponse as DealershipInformationPage[]).find(
        (d) => Number(d.hovedafdelingId) === salesVideoResponse?.departmentId
    );
    if (!department) return;

    await submitActivity({ videoDetailId: salesVideoResponse.id, activityType: 'SeenByCustomer' });
    return { ...salesVideoResponse, department };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ssrForProductSpots = async (page: umbraco.Page, globalPlpSettings: umbraco.GlobalProductListSettings, query: ParsedUrlQuery) => {
    if (!('contentSpotsSettings' in page)) return;
    const productListSpots = page.contentSpotsSettings.filter(
        (x) => x.alias === 'productListWithFilter' || x.alias === 'productListWithoutFilter' || x.alias === 'productRibbon'
    );
    let filterQuery: string[] = [];
    if (query.filter) {
        if (Array.isArray(query.filter)) {
            filterQuery = [...query.filter];
        } else {
            filterQuery.push(query.filter);
        }
    }
    for (const spot of productListSpots) {
        let paginationSize = globalPlpSettings.paginationSize;
        let config = 'HireTestConfig';
        if (spot.alias === 'productListWithFilter' || spot.alias === 'productListWithoutFilter' || spot.alias === 'productRibbon') {
            if (spot.alias === 'productListWithFilter' || spot.alias === 'productListWithoutFilter') {
                const pageParam = query.page;
                if (pageParam) {
                    const convertedPageParam = Number(pageParam);
                    if (!Number.isNaN(convertedPageParam)) {
                        paginationSize = convertedPageParam * globalPlpSettings.paginationSize;
                    }
                }
            }
            const [filterConfigurationPage, error] = await getPageById(spot.filterConfiguration.key);
            if (filterConfigurationPage && !error) {
                config = filterConfigurationPage[0].key;
                const marketingProductCards = (filterConfigurationPage[0] as FilterConfiguration).marketingProductCards.filter(
                    (x) => x.index <= paginationSize
                );
                paginationSize = paginationSize - marketingProductCards.length;
            }
        }
        if (spot.alias === 'productRibbon') paginationSize = spot.maxNumberOfProducts === 0 ? 12 : spot.maxNumberOfProducts;
        const baseFilterArray = (spot as ProductListWithFilter).baseFilterSetup ?? [];
        const baseFilter = baseFilterArray.length > 0 ? baseFilterArray[0] : DefaultBaseFilterSetup;
        const allFilters =
            spot.alias === 'productRibbon'
                ? [`${AvailabilityFilterKey}__${baseFilter.defaultAvailability}`]
                : [...filterQuery, `${AvailabilityFilterKey}__${baseFilter.defaultAvailability}`];
        if (baseFilter.defaultAvailability !== 'Demo') {
            allFilters.push(`${PaymentTypeFilterKey}__${baseFilter.defaultPaymentType ?? DefaultBaseFilterSetup.defaultPaymentType}`);
        }
        const vehicleType = baseFilter.alias === 'carBaseFilter' ? 'Car' : 'Van';
        const [result, error] = await getProductList(
            {
                configurationId: config,
                filters: allFilters,
                from: 0,
                take: paginationSize,
            },
            vehicleType
        );
        if (result && !error) {
            (spot as ProductListWithFilter).initialProductListData = result;
            (spot as ProductListWithFilter).initialProducts = [...result.vehicles.map((x) => mapCarDetailsToProductCard(x))];
            (spot as ProductListWithFilter).initialFacetsArray = createFacetArray(result, globalPlpSettings, baseFilter);
            (spot as ProductListWithFilter).initialActiveFilters = query.filter ?? '';
        }
    }

    const sixPlusSixSpots = page.contentSpotsSettings.filter((x) => x.alias === 'sixPlusSixBundle');
    for (const spot of sixPlusSixSpots) {
        const [result, error] = await getAllBundles();
        if (result && !error) {
            const allVehicleIds: string[] = result.bundles.reduce(
                (acc, curr) => (acc = [...acc, curr.variant1Id, curr.variant2Id]),
                new Array<string>()
            );
            const [vehiclesInBundles, vehiclesError] = await getCarByIds(allVehicleIds);
            if (vehiclesInBundles && Array.isArray(vehiclesInBundles) && !vehiclesError) {
                (spot as SixBySixBundleSpot).bundles = result.bundles.filter(
                    (x) =>
                        vehiclesInBundles.find((vh) => vh.vehicleId === x.variant1Id) && vehiclesInBundles.find((vh) => vh.vehicleId === x.variant2Id)
                );
                (spot as SixBySixBundleSpot).carsInBundle = vehiclesInBundles;
            }
        }
    }

    // Super modules
    const carExplorationModules = page.contentSpotsSettings.filter((x) => x.alias === 'carExplorationModule');
    for (const spot of carExplorationModules) {
        const ceSpot = spot as CarExplorationModule;
        // Flatten all cards to prep for request
        const allCardsFromUmbraco = ceSpot.tabs.map((x) => x.cards).reduce((acc, curr) => [...acc, ...curr], []);
        // Request and set data as extra data on spot.
        const [cardData, error] = await getCarExplorationCardData(ceSpot.priceType, allCardsFromUmbraco);
        if (cardData && !error) {
            ceSpot.cardData = cardData;
        }
    }

    //Shop products
    const shopProductListSpots = page.contentSpotsSettings.filter((x) => x.alias === 'shopProductList');
    for (const spot of shopProductListSpots) {
        const shopPlpSpot = spot as ShopProductList;
        const pageParam = query.page;
        const filterConfigId = shopPlpSpot.shopFilterConfiguration?.key ?? '';

        let paginationSize = globalPlpSettings.shopPaginationSize;
        if (pageParam) {
            const convertedPageParam = Number(pageParam);
            if (!Number.isNaN(convertedPageParam)) {
                paginationSize = convertedPageParam * globalPlpSettings.shopPaginationSize;
            }
        }
        if (shopPlpSpot.shopFilterConfiguration && shopPlpSpot.shopFilterConfiguration.marketingProductCards.length > 0) {
            const marketingProductCardsOnFirstPage = shopPlpSpot.shopFilterConfiguration.marketingProductCards.filter(
                (x) => x.index <= paginationSize
            );
            paginationSize = paginationSize - marketingProductCardsOnFirstPage.length;
        }
        const ssrFilters = Array.isArray(query.filter) ? query.filter : [query.filter];
        const sortParam = Array.isArray(query.sort) ? query.sort[0] : query.sort;
        const [filterResponse, error] = await getShopProductsByCategory({
            categoryId: shopPlpSpot.categoryId,
            filters: query.filter !== undefined && ssrFilters.length > 0 ? ssrFilters : [],
            take: paginationSize,
            from: 0,
            sort: sortParam && sortParam.length > 0 ? sortParam : undefined,
            filterConfigId: filterConfigId,
        });
        const subCategories = page.children?.filter((x) => x.contentTypeAlias === 'shopCategoryPage');
        const [parentPage, parentPageError] = await getPageById(page.parentId);
        if (subCategories && subCategories.length > 0) {
            const mappedPages = subCategories.map((x) => x as umbraco.ShopCategoryPage);
            shopPlpSpot.categories = mappedPages;
        } else if (parentPage && parentPage.length > 0 && !parentPageError) {
            const siblings = parentPage[0].children?.filter((x) => x.contentTypeAlias === 'shopCategoryPage');
            if (siblings && siblings.length > 0) {
                const mappedPages = siblings.map((x) => x as umbraco.ShopCategoryPage);
                shopPlpSpot.categories = mappedPages;
            }
        }
        if (parentPage && parentPage.length > 0) {
            if (parentPage[0].contentTypeAlias === 'shopCategoryPage') shopPlpSpot.backLink = parentPage[0] as umbraco.ShopCategoryPage;
        }
        if (filterResponse && !error) {
            const shopFacets = createShopProductListData(filterResponse.aggregates, globalPlpSettings);
            const selectedSorting = filterResponse.selectedSorting ? filterResponse.selectedSorting.key : '';
            shopPlpSpot.ssrProductListData = {
                facets: shopFacets,
                products: filterResponse.products,
                filters: query.filter ?? '',
                total: filterResponse.total,
                selectedSorting,
                sortOptions: filterResponse.sortOptions.map((x) => x.key),
                filterConfigId: filterConfigId,
            };
        }
    }
};
