import { getPage, getPageByDataTypeAlias, getSiteSettings, umbraco } from '../lib/api';
import { useEffect, useMemo, useState } from 'react';
import { useUrlHandler } from '../hooks/use-url-handler';
import { ErrorHandlingProvider } from '../error-handling';
import DefaultErrorPage from 'next/error';
import { ssrForProductSpots } from '../lib/ssr-helpers';
import { filterSpotsInUnknownPageType } from '../components/spots/helpers';
import dynamic from 'next/dynamic';
import { HesselSiteProps } from '../components/site/hessel-site.component';
import { useRouter } from 'next/router';
import { getEntityIdFromUrl } from '../error-handling/helpers/404-helpers';
import { RelewiseRelatedVehicles } from '../lib/api/models/umbraco/content-spot';

const DynamicHesselSite = dynamic<HesselSiteProps>(() => import('../components/site/hessel-site.component').then((module) => module.HesselSite));

export default function Custom404(): JSX.Element {
    const [cmsData, setCmsData] = useState<
        | {
              siteSettings?: umbraco.SiteSettings;
              page?: umbraco.Page;
          }
        | undefined
    >(undefined);

    const getSiteComponents = () => {
        const isContentPage = cmsData && cmsData.page && 'contentSpotsSettings' in cmsData.page;

        if (!cmsData?.page || !cmsData?.siteSettings) {
            return <DefaultErrorPage statusCode={404} />;
        }

        if (!isContentPage) {
            return <DefaultErrorPage statusCode={404} />;
        }

        return <DynamicHesselSite page={cmsData?.page} siteSettings={cmsData?.siteSettings} />;
    };

    const router = useRouter();

    useUrlHandler();

    useEffect(() => {
        const loadSiteSettings = async () => {
            const vehicleEntityId = getEntityIdFromUrl(router.asPath);
            const [siteSettings] = await getSiteSettings();

            if (vehicleEntityId) {
                const [pages] = await getPageByDataTypeAlias('productDetailsPage');
                if (pages && pages.length > 0) {
                    const page = pages[0] as umbraco.ProductDetailsPage;
                    await filterSpotsInUnknownPageType(page);
                    const globalPlpSettings = siteSettings?.marketSettings[0].productListSettings;
                    if (globalPlpSettings) {
                        await ssrForProductSpots(page, globalPlpSettings, {});
                    }
                    const relewiseRelatedVehiclesSpot: RelewiseRelatedVehicles = {
                        entityId: vehicleEntityId,
                        alias: 'relewiseRelatedVehicles',
                        fromDate: new Date('0001-01-01T00:00:00'),
                        toDate: new Date('0001-01-01T00:00:00'),
                        hideBlock: false,
                        scrollAnchorId: '',
                        header: page.relatedVehiclesHeader,
                        subText: page.relatedVehiclesSubtext,
                    };
                    const indexOfRelatedVehiclesSpot =
                        !page.positionOfRelatedVehiclesSpot || page.positionOfRelatedVehiclesSpot === 0 ? 0 : page.positionOfRelatedVehiclesSpot - 1;
                    const contentArray = [...page.contentSpotsSettings];
                    const afterRelated = contentArray.splice(indexOfRelatedVehiclesSpot);
                    const returnPage: umbraco.ContentPage = {
                        ...page,
                        contentTypeAlias: 'contentPage',
                        filteredContentSpots: [...contentArray, relewiseRelatedVehiclesSpot, ...afterRelated],
                        reepayFormContent: [],
                        serviceComparison: [],
                        sidebarContent: page.sidebarContent,
                    };
                    return {
                        siteSettings,
                        page: returnPage,
                    };
                }
            } else {
                const [page] = await getPage('/404');
                return {
                    siteSettings,
                    page,
                };
            }
        };
        loadSiteSettings().then((res) =>
            setCmsData({
                siteSettings: res?.siteSettings,
                page: res?.page,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Note: Make sure to Add Error messages to the newly added site (In Umbraco).
     */
    const errors = useMemo(() => {
        return cmsData?.siteSettings?.marketSettings
            .map(({ errorContent }) => errorContent.children)
            .reduce((acc, errorInfo) => [...acc, ...errorInfo], []);
    }, [cmsData?.siteSettings?.marketSettings]);

    return cmsData && cmsData?.siteSettings && cmsData?.page ? (
        <ErrorHandlingProvider errors={errors ?? []}>{cmsData.page && cmsData.siteSettings ? getSiteComponents() : null}</ErrorHandlingProvider>
    ) : (
        <></>
    );
}
