export const getEntityIdFromUrl = (url: string): string | undefined => {
    const slug = url.split('/');
    const isPdpUrl = url.indexOf('/m/') > -1;
    if (isPdpUrl) {
        const vehiclePart = slug.pop();
        if (vehiclePart) {
            const splitVehiclePart = vehiclePart.split('-');
            if (splitVehiclePart.length === 5) return splitVehiclePart[4].split('?')[0];
        }
    }
    return undefined;
};
